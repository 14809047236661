<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        lg="1"
        class=".d-none hidden-md-and-down"
      />
      <v-col
        cols="12"
        lg="10"
        md="12"
        sm="12"
      >
        <div>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  {{ $t('registerUserInfo') }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              lg="8"
              md="12"
              sm="12"
            >
              <v-form>
                <base-material-card color="primary">
                  <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                      {{ $t('registerCardInfoTitle') }}
                    </div>
                  </template>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="registerUser.Name"
                          :error-messages="nameErrors"
                          class="purple-input"
                          :label="$t('registerCardLabelName')"
                          dense
                          @input="$v.registerUser.Name.$touch()"
                          @blur="$v.registerUser.Name.$touch()"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="registerUser.CompanyName"
                          :error-messages="companyNameErrors"
                          class="purple-input"
                          :label="$t('registerCardLabelOrg')"
                          dense
                          @input="$v.registerUser.CompanyName.$touch()"
                          @blur="$v.registerUser.CompanyName.$touch()"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="registerUser.JobTitle"
                          :error-messages="titleErrors"
                          class="purple-input"
                          :label="$t('registerCardLabelTitle')"
                          dense
                          @input="$v.registerUser.JobTitle.$touch()"
                          @blur="$v.registerUser.JobTitle.$touch()"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                      >
                        <v-text-field
                          v-model="registerUser.MobilePhone"
                          v-mask="'(###) ###-####'"
                          :error-messages="mobilePhoneErrors"
                          class="purple-input"
                          :label="$t('registerCardLabelPhone')"
                          dense
                          @input="$v.registerUser.MobilePhone.$touch()"
                          @blur="$v.registerUser.MobilePhone.$touch()"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        lg="6"
                        md="6"
                        sm="12"
                      >
                        <v-text-field
                          v-model="registerUser.EMail"
                          :error-messages="eMailErrors"
                          class="purple-input"
                          :label="$t('registerCardLabelEMail')"
                          dense
                          @input="$v.registerUser.EMail.$touch()"
                          @blur="$v.registerUser.EMail.$touch()"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="registerUser.EastmanContactName"
                          :error-messages="contactNameErrors"
                          class="purple-input"
                          :label="$t('registerCardLabelContactName')"
                          dense
                          @input="$v.registerUser.EastmanContactName.$touch()"
                          @blur="$v.registerUser.EastmanContactName.$touch()"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <template v-slot:actions>
                    <div>
                      <v-btn
                        color="success"
                        :disabled="disableSubmit"
                        @click="onRegisterSubmit"
                      >
                        {{ $t('buttonSubmit') }}
                      </v-btn>
                      <v-btn
                        text
                        to="/"
                      >
                        {{ $t('buttonClose') }}
                      </v-btn>
                    </div>
                  </template>
                </base-material-card>
              </v-form>
            </v-col>
            <v-col
              cols="12"
              lg="4"
              md="12"
              sm="12"
            >
              <help-listing :type="helpType" />
              <!-- <div>
                Application Help comes here
              </div> -->
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="1"
        class=".d-none hidden-md-and-down"
      />
    </v-row>
    <error-dialog
      :dialog="showErrorDialog"
      title="Failed to Load the Application"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay :processing="modelProcessing" />
  </v-container>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
  // import userConfig from '@/configuration/userConfiguration'
  import userAPI from '@/services/userServices'

  function initialState () {
    return {
      showErrorDialog: false,
      errorMessage: '',
      environment: process.env.VUE_APP_ENVIRONMENT,
      registerUser: {
        UserId: 0,
        LoginUserId: '',
        UserObjectId: '',
        Name: '',
        CompanyName: '',
        JobTitle: '',
        MobilePhone: '',
        EMail: '',
        EastmanContactName: '',
        StatusCode: 1,
        ReCaptchaToken: '',
        ClientIPAddress: '',
        ClientBrowser: '',
      },
      modelProcessing: false,
      response: null,
      showAlertMessage: false,
      alertType: 'info',
      alertMessage: '',
      helpType: 1,
    }
  }
  export default {
    name: 'RegisterUser',
    components: {
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
      HelpListing: () => import('@/views/components/core/HelpListing'),
    },
    data: function () {
      return initialState()
    },
    validations: {
      registerUser: {
        Name: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(80),
        },
        CompanyName: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(120),
        },
        JobTitle: {
          required,
        },
        MobilePhone: {
          required,
        },
        EMail: {
          required,
          email,
        },
        EastmanContactName: {
          required,
        },
      },
    },
    computed: {
      ...mapFields({
        clientIPAddress: 'clientIPAddress',
      }),
      disableSubmit: function () {
        return this.$v.$invalid
      },
      nameErrors () {
        const errors = []
        if (!this.$v.registerUser.Name.$dirty) return errors
        !this.$v.registerUser.Name.maxLength &&
          errors.push(this.$t('registerCardErrorNameMax'))
        !this.$v.registerUser.Name.minLength &&
          errors.push(this.$t('registerCardErrorNameMin'))
        !this.$v.registerUser.Name.required &&
          errors.push(this.$t('registerCardErrorNameRequired'))
        return errors
      },
      companyNameErrors () {
        const errors = []
        if (!this.$v.registerUser.CompanyName.$dirty) return errors
        !this.$v.registerUser.CompanyName.maxLength &&
          errors.push(this.$t('registerCardErrorOrgMax'))
        !this.$v.registerUser.CompanyName.minLength &&
          errors.push(this.$t('registerCardErrorOrgMin'))
        !this.$v.registerUser.CompanyName.required &&
          errors.push(this.$t('registerCardErrorOrgRequired'))
        return errors
      },
      titleErrors () {
        const errors = []
        if (!this.$v.registerUser.JobTitle.$dirty) return errors
        !this.$v.registerUser.JobTitle.required &&
          errors.push(this.$t('registerCardErrorTitleRequired'))
        return errors
      },
      mobilePhoneErrors () {
        const errors = []
        if (!this.$v.registerUser.MobilePhone.$dirty) return errors
        !this.$v.registerUser.MobilePhone.required &&
          errors.push(this.$t('registerCardErrorPhoneRequired'))
        return errors
      },
      eMailErrors () {
        const errors = []
        if (!this.$v.registerUser.EMail.$dirty) return errors
        !this.$v.registerUser.EMail.email &&
          errors.push(this.$t('registerCardErrorEMailInvalid'))
        !this.$v.registerUser.EMail.required &&
          errors.push(this.$t('registerCardErrorEMailRequired'))
        return errors
      },
      contactNameErrors () {
        const errors = []
        if (!this.$v.registerUser.EastmanContactName.$dirty) return errors
        !this.$v.registerUser.EastmanContactName.required &&
          errors.push(this.$t('registerCardErrorContactRequired'))
        return errors
      },
    },
    methods: {
      onRegisterSubmit: async function (event) {
        this.modelProcessing = true
        await this.$recaptchaLoaded()
        this.$recaptcha('register')
          .then((token) => {
            this.registerUser.ReCaptchaToken = token
            this.registerUser.ClientIPAddress = this.clientIPAddress
            this.registerUser.ClientBrowser = this.$browserDetect.meta.name + '(version: ' + this.$browserDetect.meta.version + ')'
            userAPI
              .registerUser(this.registerUser)
              .then((response) => {
                this.modelProcessing = false
                Object.assign(this.$data, initialState())
                this.$router.push({
                  path: '/registerComplete',
                })
              })
              .catch((error) => {
                this.showErrorDialog = true
                this.errorMessage = error.message
                this.modelProcessing = false
              })
          })
          .catch((error) => {
            this.showErrorDialog = true
            this.message = error.message
            this.modelProcessing = false
          })
      },
    },
  }
</script>
